<template>
  <div class="html">
    <div class="head">
      <div class="item-bottom-nav">
        <div
          v-for="(item, index) in navList"
          :key="index"
          style="margin-right: 50px"
        >
          <div class="clr"></div>
          <p class="title">{{ item.title }}</p>
          <div class="item-content">
            <p
              v-for="(items, indexs) in item.child"
              @click="gethref(index, indexs)"
              :key="indexs"
            >
              {{ items }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <img src="../assets/images/erwm.png" class="ewm" alt="" />
        <p style="color: #fff; font-size: 12px; text-align: center">
          扫码关注公众号
        </p>
      </div>
    </div>
    <div class="information">
      Copyright©山东六牛网络科技有限公司  <a class="ljer" href="https://beian.miit.gov.cn/" target="_blank"> 鲁ICP备15044287号-14</a>
      山东省运营中心：山东省临沂市国家中印科技创新园 联系电话： 400-1616-317 /
      0539-8809134
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          title: "关于我们",
          child: ["课程体系", "招商加盟", "关于我们"],
        },
        {
          title: "平台",
          child: ["学习培训系统", "教务系统"],
        },
        {
          title: "集团链接",
          child: ["六牛网络科技", "智能校管", "孟母选校"],
        },
      ],
    };
  },
  methods: {
    gethref(e, key) {
      let url = "";
      if (e == 2) {
        url =
          key == 0
            ? "https://www.liuniukeji.com/"
            : key == 1
            ? "https://xgj.weilaijidi.cn/znxg/index.html"
            : "https://xgj.weilaijidi.cn/mmxx/index.html";
        window.open(url, "_blank");
      } else if (e == 1) {
        url =
          key == 0
            ? "http://teach.lnsteam.com/"
            : "http://teach.lnsteam.com/admin/Login/login.html";
        window.open(url, "_blank");
      } else {
        url =
          key == 0
            ? "https://www.liuniukeji.com/"
            : key == 1
            ? "http://lnsteam.com/contactus"
            : "http://lnsteam.com/company";
        if (key == 0) return;
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang='less' scoped>
.html {
  background: #525873 !important;
}
.head {
  width: 1100px;
  margin: auto;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .clr {
    width: 29px;
    height: 3px;
    background: #ffffff;
    margin-bottom: 5px;
  }
  .item-bottom-nav {
    color: #ffffff;
    width: 650px;
    display: flex;

    .title {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .item-content {
      font-size: 12px;
      line-height: 2;
      p {
        cursor: pointer;
      }
      p:hover {
        color: #00baff;
      }
    }
  }
  .ewm {
    width: 102px;
    height: 102px;
  }
}
.information {
  height: 42px;
  margin: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
}
.ljer{
  margin-left: 4px;
  margin-right: 4px;
  color: #FFFFFF;
}
</style>