<template>
  <div>
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>
    <img src="../assets/images/gsjs_banner.png" class="banner" alt="" />
    <div class="customized-first">
      <div style="max-width: 655px">
        <p class="customized-title">让教育赋予科技力量</p>
        <p class="customized-content">
          未来基地是一家专注于3-16岁人工智能教育的全生态链企业，隶属于深圳市六牛教育科技有限公司。未来基地成立于2017年，坐落于国家中印国际科技创新园，占地136亩，并拥有技术研发团队、课程教研团队、校区运营团队、宣传推广团队和师资团队共计140余人，并于2019年成为国家教育部产学研合作协同育人重点项目企业单位。
        </p>
        <p class="customized-content" style="margin-top: 20px">
          在未来基地，教师和互联网行业人才并存，母公司六牛科技为高端互联网定制开发公司，拥有开发人员130余人，深耕互联网编程行业多年，这让未来基地课程研发以及授课心法独具特色。
        </p>
      </div>
      <img src="../assets/images/kjll_img.png" class="first-img" alt="" />
    </div>
    <div
      :class="index == 1 ? '' : 'background1'"
      v-for="(item, index) in cultureList"
      :key="index"
    >
      <div class="license">
        <p class="license-title">{{ item.title }}</p>
        <div class="license-image">
          <div
            class="item-license"
            v-for="(items, indexs) in item.child"
            :key="indexs"
          >
            <img :src="items.image" class="license_img" alt="" />
            <div class="item-title">{{ items.name }}</div>
            <div
              class="item-content"
              :style="indexs == 2 && index == 0 ? 'white-space: nowrap;' : ''"
            >
              {{ items.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background1">
      <div class="license">
        <p class="license-title" style="margin-bottom: 20px">和我们聊一聊</p>
        <p class="license-content">
          如果您想进一步了解我们，无需犹豫，即刻联系我们！
        </p>
        <div class="license-image">
          <img src="../assets/images/lyl_img.png" class="license-img" alt="" />
        </div>
      </div>
    </div>
    <div class="customer">
      <img src="../assets/images/kefu.png" class="customer_img" alt="" />
      <p>客服在线 点击咨询</p>
    </div>
    <indexbottom></indexbottom>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";
export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      current: 5,
      cultureList: [
        {
          title: " 我们的企业文化",
          child: [
            {
              name: "使命",
              content: "开启每一个孩子的人工智能探索之门",
              image: require("../assets/images/qywm_icon1.png"),
            },
            {
              name: "愿景",
              content: "让中国的每一个乡镇都有未来基地",
              image: require("../assets/images/qywm_icon2.png"),
            },
            {
              name: "价值观",
              content: "简单、高效、坦诚、担当、团结、创新",
              image: require("../assets/images/qywm_icon3.png"),
            },
          ],
        },
        {
          title: " 为什么加入我们？",
          child: [
            {
              name: "专注的使命",
              content:
                "为开启每一个孩子的人工智能探索之门而奋斗，提供专业的少儿编程课程，至诚为合作伙伴",
              image: require("../assets/images/qywm_icon4.png"),
            },
            {
              name: "自主和赋能",
              content:
                "让品牌拥有更强自主性，未来基地提供工具、技术诀窍和支持，协助品牌开拓创新",
              image: require("../assets/images/qywm_icon5.png"),
            },
            {
              name: "成长和进步",
              content:
                "除了全新的教育理念和方法，可随时感知科技行业的创新能力提升",
              image: require("../assets/images/qywm_icon6.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang='less' scope>
.background1 {
  background: #f8f8f8;
}
.banner {
  width: 100%;
  height: auto;
}
.customized-first {
  display: flex;
  align-items: center;
  width: 1100px;
  height: 386px;
  justify-content: space-between;
  margin: auto;
  .first-img {
    width: 227px;
    height: 270px;
  }
  .customized-title {
    font-size: 36px;
    color: #000;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .customized-content {
    font-size: 15px;
    color: #7a7a7a;
  }
}

.license {
  padding: 60px 0;
  width: 1100px;
  margin: auto;
  text-align: center;
  .license-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .license-content {
    font-size: 15px;
    color: #7a7a7a;
    margin-bottom: 40px;
  }
  .license-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    .item-license {
      display: flex;
      flex-direction: column;
      align-items: center;
      .license_img {
        width: 102px;
        height: 102px;
        margin-bottom: 15px;
      }
      .item-title {
        font-size: 23px;
        color: #000;
        margin-bottom: 10px;
      }
      .item-content {
        font-size: 16px;
        color: #9e9e9e;
        max-width: 260px;
      }
    }
  }
  .license-img {
    width: 1100px;
    height: 140px;
  }
}
</style>