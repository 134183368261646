import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue' //首页
import course from '../views/course.vue' //课程体系
import train from '../views/train.vue' //师资培训
import customized from '../views/customized.vue' //平台定制
import match from '../views/match.vue' //赛事
import company from '../views/company.vue' //公司介绍
import contactus from '../views/contactus.vue' //公司介绍

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/course',
    name: 'course',
    component: course
  },
  {
    path: '/train',
    name: 'train',
    component: train
  },
  {
    path: '/customized',
    name: 'customized',
    component: customized
  },
  {
    path: '/match',
    name: 'match',
    component: match
  },
  {
    path: '/company',
    name: 'company',
    component: company
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: contactus
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // history模式url不带#
  // history: createWebHashHistory, hash模式url带#
  routes,
})

export default router
