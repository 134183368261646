<template>
  <div class="html">
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>
    <img src="../assets/images/ssjfw_banner.png" class="banner" alt="" />
    <div class="match-box">
      <div
        class="item-match"
        v-for="(item, index) in matchList"
        :key="index"
        @click="getdetail(item.id)"
      >
        <img :src="item.image" class="match_img" alt="" />
        <div class="match-name">{{ item.name }}</div>
      </div>
    </div>
    <indexbottom></indexbottom>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";
export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      current: 4,
      matchList: [
        {
          id: 1,
          image: require("../assets/images/bs_img1.png"),
          name: "2021世界机器人大会",
        },
        {
          id: 2,
          image: require("../assets/images/bs_img2.png"),
          name: "中国电子学会考级",
        },
        {
          id: 3,
          image: require("../assets/images/bs_img3.png"),
          name: "C++信息学奥赛",
        },
        {
          id: 4,
          image: require("../assets/images/bs_img4.png"),
          name: "全国青少年科技创新大赛",
        },
        {
          id: 5,
          image: require("../assets/images/bs_img5.png"),
          name: "全国青少年人工智能创新挑战赛",
        },
        {
          id: 6,
          image: require("../assets/images/bs_img6.png"),
          name: "NOC",
        },
      ],
    };
  },
  methods: {
    getdetail(e) {
      let url = "";
      if (e == 1) {
        url = "http://www.worldrobotconference.com/cn";
      } else if (e == 2) {
        url = "http://s.noc.net.cn/";
      } else if (e == 3) {
        url = "http://www.xrmaker.com/#/matchAbout";
      } else if (e == 4) {
        url = "https://castic.cyscc.org/";
      } else if (e == 5) {
        url = "https://www.noi.cn/";
      } else if (e == 6) {
        url = "https://www.qceit.org.cn/bos/default.html";
      }
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang='less'>
.html {
  background: #f8f8f8;
}
.banner {
  width: 100%;
  height: 500px;
}
.match-box {
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px auto;
  .item-match {
    width: 540px;
    background: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.03999999910593033);
    border-radius: 8px 8px 8px 8px;
    .match_img {
      width: 100%;
      height: 280px;
    }
    .match-name {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #333;
    }
  }
}
</style>