import { post } from './utils';

const contact = (query) => {
    return post('Index/contact', query);
};



export default {
    contact: contact,
};