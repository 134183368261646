<template>
  <div class="html">
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>
    <img src="../assets/images/szpx_banner.png" class="banner" alt="" />
    <div class="index_modular" style="background: #fff">
      <p class="modular-title">未来基地为您提供</p>
      <div class="modular_box">
        <div
          class="item-modular"
          v-for="(item, index) in modularList"
          :key="index"
        >
          <img :src="item.image" class="modular-img" alt="" />
          <p class="modular_title" style="margin: 20px 0">{{ item.title }}</p>
          <div>
            <p
              style="margin-bottom: 5px"
              class="modular_content"
              v-for="(items, indexs) in item.child"
              :key="indexs"
            >
              {{ items }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="index_modular" style="height: 700px">
      <p class="modular-title">专业的老师培训</p>
      <p class="modular-content">
        为教育机构精心打造少儿编程教师培训基地，提供优质的少儿编程行业相关培训
      </p>
      <img
        src="../assets/images/home_img_lspx.png"
        class="modular_img"
        alt=""
      />
    </div>
    <div class="procedure">
      <div class="procedure-title">学员完整学习周期的闭环工具</div>
      <div class="procedure-content">
        招生、教学、运营培训服务，品牌及营销推广支持，开班、授课、续费长期运营支持
      </div>
      <div
        class="procedure-item"
        v-for="(item, index) in procedureList"
        :key="index"
      >
        <p class="procedure-name">{{ item.name }}</p>
        <p class="procedure-desc">{{ item.desc }}</p>
        <div class="procedure-box">
          <div
            class="item-content"
            v-for="(items, indexs) in item.child"
            :key="indexs"
          >
            <div
              class="procedure-order"
              v-for="(itemm, indexx) in items.content"
              :key="indexx"
              :style="
                indexx == items.content.length - 1 ? 'margin-bottom: 0' : '0'
              "
            >
              <img :src="itemm.image" class="procedure-img" alt="" />
              <div class="procedure-right">
                <p class="right-name">{{ itemm.name }}</p>
                <p class="right-desc">{{ itemm.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer">
      <img src="../assets/images/kefu.png" class="customer_img" alt="" />
      <p>客服在线 点击咨询</p>
    </div>
    <indexbottom></indexbottom>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";
export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      current: 2,
      modularList: [
        {
          title: "行业知识",
          child: ["从宏观到微观的国家政策", "和行业发展的全面普及"],
          image: require("../assets/images/szpx_icon1.png"),
        },
        {
          title: "产品知识",
          child: ["了解平台工具功能逻辑", "系统掌握操作使用方法"],
          image: require("../assets/images/szpx_icon2.png"),
        },
        {
          title: "课程使用方法",
          child: ["熟悉课程产品形态掌握", "课程资源用途用法"],
          image: require("../assets/images/szpx_icon3.png"),
        },
        {
          title: "Scratch技能",
          child: ["掌握Scratch基础知识，", "掌握作品创作核心逻辑"],
          image: require("../assets/images/szpx_icon4.png"),
        },
        {
          title: "标准授课流程",
          child: ["从授课准备到教学评价等多", "方面标准化的教学过程培训"],
          image: require("../assets/images/szpx_icon5.png"),
        },
        {
          title: "授课技能升级",
          child: ["优秀示范课录屏，社群一对一", "磨课，不断提升教师授课技能"],
          image: require("../assets/images/szpx_icon6.png"),
        },
        {
          title: "职业成长",
          child: ["从职业规划、成长路径等多角度", "保障金牌编程教师的专业成长"],
          image: require("../assets/images/szpx_icon7.png"),
        },
        {
          title: "招生咨询理论",
          child: ["招生宣传、咨询话术等相关", "理论，保证机构的招生效果"],
          image: require("../assets/images/szpx_icon8.png"),
        },
      ],
      procedureList: [
        {
          name: "预约学员 - 营销获客",
          desc: "数字化营销,大幅降低获客成本",
          child: [
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj1.png"),
                  name: "招生海报",
                  desc: "微信刷屏裂变，高效引流到访",
                },
                {
                  image: require("../assets/images/home_bhgj3.png"),
                  name: "优惠券",
                  desc: "领券送码抵扣课时费",
                },
                {
                  image: require("../assets/images/home_bhgj2.png"),
                  name: "转介绍",
                  desc: "老学员带新学员",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj4.png"),
                  name: "报名表单",
                  desc: "发布报名信息获取客户线索",
                },
                {
                  image: require("../assets/images/home_bhgj5.png"),
                  name: "限时折扣",
                  desc: "限时折扣实现销量增长",
                },
                {
                  image: require("../assets/images/home_bhgj6.png"),
                  name: "买赠",
                  desc: "买课送课时、实物礼物",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj7.png"),
                  name: "课程推广",
                  desc: "分享课程信息至社交网络",
                },
                {
                  image: require("../assets/images/home_bhgj8.png"),
                  name: "秒杀",
                  desc: "营造限量限购氛围抢课",
                },
                {
                  image: require("../assets/images/home_bhgj9.png"),
                  name: "好友助力",
                  desc: "好友点赞，裂变营销",
                },
              ],
            },
          ],
        },
        {
          name: "未报名学员 - 咨询转化",
          desc: "潜在学员状态跟进，邀约体验方便快捷",
          child: [
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj21.png"),
                  name: "线索导入",
                  desc: "快速导入注册学员，打通线上线下",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj20.png"),
                  name: "线索来源",
                  desc: "按来源监控线索质量",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj19.png"),
                  name: "跟进记录",
                  desc: "记录跟进情况、客户事件",
                },
              ],
            },
          ],
        },
        {
          name: "在读学员 - 教学服务",
          desc: "记录学员成长轨迹，时刻关注在读学员的课程进度，提升学员满意度和续费意愿",
          child: [
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj12.png"),
                  name: "编程工具",
                  desc: "在线图形化编程、代码编程工具",
                },
                {
                  image: require("../assets/images/home_bhgj10.png"),
                  name: "成长记录",
                  desc: "记录学员上课状态和学习历程",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj11.png"),
                  name: "在线创作",
                  desc: "课前、课中、课后创作和保存作品",
                },
                {
                  image: require("../assets/images/home_bhgj13.png"),
                  name: "上课记录",
                  desc: "记录学生、老师上课情况",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj14.png"),
                  name: "学习报告",
                  desc: "学习报告自动推送给家长",
                },
                {
                  image: require("../assets/images/home_bhgj15.png"),
                  name: "请假补课",
                  desc: "针对请假学员灵活进行补课",
                },
              ],
            },
          ],
        },
        {
          name: "历史学员 - 用户运营",
          desc: "清晰收录在学员信息，高效管理学员生命周期，方便回访跟进，激活历史学员",
          child: [
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj16.png"),
                  name: "学员召回",
                  desc: "批量发送广告信息",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj17.png"),
                  name: "赛事举办",
                  desc: "举办编程创作比赛，激活历史客户",
                },
              ],
            },
            {
              content: [
                {
                  image: require("../assets/images/home_bhgj18.png"),
                  name: "问卷回访",
                  desc: "满意度回访,沉淀服务口碑",
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang='less' scoped>
.html {
  background: #f8f8f8;
}
.banner {
  height: 500px;
  width: 100%;
  object-fit: none;
}
.index_modular {
  width: 100%;
  height: 683px;
  padding: 60px 0;
  background: url("../assets/images/home_bg_zylspx.png") no-repeat;
  background-size: 100% 100% !important;
  text-align: center;
  .modular-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .modular-content {
    font-size: 15px;
    margin-top: 15px;
    color: #7a7a7a;
    text-align: center;
  }
  .modular_img {
    height: 410px;
    margin-top: 60px;
    width: 1056px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .modular-people {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .people-box {
    margin-top: 90px;
    text-align: center;
    .people-name {
      font-size: 23px;
      color: #000;
    }
    .people-rank {
      font-size: 16px;
      color: #9e9e9e;
    }
    .people-img {
      width: 200px;
      height: 200px;
      border-radius: 200px;
      margin-bottom: 40px;
    }
  }
  .modular_box {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .item-modular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 0;
    margin-bottom: 76px;
    width: 260px;
    height: 200px;
    border-radius: 10px 10px 10px 10px;
    .modular-img {
      width: 96px;
      height: 96px;
    }
    .modular_title {
      font-size: 23px;
      color: #000;
    }
    .modular_content {
      font-size: 16px;
      color: #9e9e9e;
      text-align: center;
    }
  }
}
.procedure {
  width: 1100px;
  margin: auto;
  padding: 60px 0;
  .procedure-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
  .procedure-content {
    color: #7a7a7a;
    font-size: 15px;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 40px;
  }
  .procedure-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .procedure-item {
    margin-bottom: 40px;
    .procedure-name {
      font-size: 20px;
      color: #333;
      margin-bottom: 10px;
    }
    .procedure-desc {
      font-size: 15px;
      color: #7a7a7a;
      margin-bottom: 20px;
    }
    .item-content {
      padding: 29px 38px;
      width: 347px;
      background: #ffffff;
      box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.03999999910593033);
      border-radius: 8px 8px 8px 8px;
      .procedure-order {
        display: flex;
        margin-bottom: 30px;
      }
      .procedure-img {
        width: 46px;
        height: 46px;
        margin-right: 20px;
      }
      .procedure-right {
        .right-name {
          font-size: 15px;
          color: #333;
          margin-bottom: 5px;
        }
        .right-desc {
          color: #7a7a7a;
          font-size: 12px;
        }
      }
    }
  }
}
</style>