import axios from 'axios';
import qs from 'qs';
import router from '../router';
// const ApiUrl = 'http://future.lnkj6.com/api.php/';
const ApiUrl = 'http://teach.lnsteam.com/api.php/';
// const ApiUrl = 'http://aaa.future_school.lnkj6.com/api.php/'
const axUrl = 'http://' + window.location.host + '/api.php/'
axios.defaults.timeout = 30000;
// axios.defaults.baseURL = "/api";  //好像没啥用
// access-Control-Allow-Origin:*,
axios.defaults.headers.head['Access-Control-Allow-Origin'] = '*';
// ("Access-Control-Allow-Origin:*");
//http request 拦截器
// 请求超时时间
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;application/json;charset=UTF-8';

// Content-Type: application/json ： 请求体中的数据会以json字符串的形式发送到后端
// Content-Type: application/x-www-form-urlencoded：请求体中的数据会以普通表单形式（键值对）发送到后端
// Content-Type: multipart/form-data： 它会将请求体的数据处理为一条消息，以标签为单元，用分隔符分开。既可以上传键值对，也可以上传文件。
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data) {

    // data.user_type = 2;
    // data.lang = 1;
    return new Promise((resolve, reject) => {
        axios.post(ApiUrl + url, qs.stringify(data)).then(
            (response) => {
                resolve(response.data);
                if (response.data.status == 1) {
                    resolve(response.data);
                } else if (response.data.status == 999) {
                    resolve(response.data);
                    router.push({
                        path: '/',
                    });
                    sessionStorage.clear();
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
export function posts(url, data) {
    // data.user_type = 2;
    // data.lang = 1;
    return new Promise((resolve, reject) => {
        axios.post(axUrl + url, qs.stringify(data)).then(
            (response) => {
                resolve(response.data);
                if (response.data.status == 1) {
                    resolve(response.data);
                } else if (response.data.status == 999) {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
/**
 * 
 * @param {String} str 
 * @returns 特殊字符转义
 */
export function htmlDecodeByRegExp(str) {
    str = str.replace(/&amp;/g, '&')
    str = str.replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, '"')
        .replace(/&nbsp;/g, ' ')
        .replace(/\r/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/<img/g, '<img style="max-width:1000px"')
    return str
}

/**
 *
 * @param {String} url 接口链接
 * @param {file} file 上传的file
 * @returns 使用这个axios时用fromData打包数据
 */
export function uploadImg(url, data) {
    // console.log(data);
    return new Promise((resolve, reject) => {
        const http = axios.create();
        http({
            method: 'POST',
            url: ApiUrl + url,
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
    // const http = axios.create({
    //     baseURL: ApiUrl
    // })
    // return http({
    //     method: "POST",
    //     url,
    //     data: file,
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // })
    // // eslint-disable-next-line quotes
}
/**
 * 节流
 */
function noMultipleClicks(methods) {
    let that = this;
    if (that.noClick) {
        that.noClick = false;
        methods();
        setTimeout(function () {
            that.noClick = true;
        }, 2000)
    } else {
        that.$message({
            type: "warning",
            message: '请不要重复点击',
        });
    }
}
/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}
const ApiUrls = 'http://future.lnkj6.com';

export default {
    noMultipleClicks: noMultipleClicks,
    ApiUrls: ApiUrls,
    htmlDecodeByRegExp: htmlDecodeByRegExp,
};