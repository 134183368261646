<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang='less'>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.customer {
  width: 221px;
  height: 46px;
  background: #00baff;
  border-radius: 23px 23px 23px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  opacity: 0;
  right: 50px;
  .customer_img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}
.index_popup {
  position: fixed;
  right: 20px;
  top: 50%;
}
.html {
  padding-top: 78px;
}
.fiexd {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 2px 0 3px 1px rgba(0, 0, 0, 0.2);
}
</style>
