<template>
  <div class="html">
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>
    <div class="banner" :style="{ height: height + 'px' }">
      <img
        src="../assets/images/pingtdz_banner.png"
        class="banner_img"
        alt=""
        ref="banner_img"
      />
      <img
        src="../assets/images/banner_btn.png"
        class="banner_btn"
        :style="{ top: Pheight + 'px' }"
        @click="getobtain"
      />
    </div>
    <div class="customized-first">
      <img src="../assets/images/ptdz_img2.png" class="first-img" alt="" />
      <div style="max-width: 500px">
        <p class="customized-title">为机构提供优质课程体系和师资培训</p>
        <p class="customized-content">
          升级课程的基础上，为机构提供少儿编程行业知识及师资培训，让机构自身品牌壮大，提升品牌口碑和生源。
        </p>
      </div>
    </div>
    <div style="background: #fff">
      <div class="customized-first" style="background: none">
        <div style="max-width: 500px">
          <p class="customized-title">帮助机构建立少儿编程学习平台</p>
          <p class="customized-content">
            拥有丰富营销宣传素材库，实时提供更新营销内容，为机构提供定制营销素材库，并提供多渠道宣传方案，实现高效招生目标。
          </p>
        </div>
        <img src="../assets/images/ptdz_img1.png" class="first-img" alt="" />
      </div>
    </div>
    <div class="customized-first">
      <img src="../assets/images/ptdz_img3.png" class="first-img" alt="" />
      <div style="max-width: 500px">
        <p class="customized-title">编程考级和赛事服务</p>
        <p class="customized-content">
          作为我们的合作机构，未来基地支持各机构学院编程考试和赛事活动，授权成为青少年技术等级测评培训点，不定期在平台开展赛事活动，营造良好少儿编程学习交流氛围。
        </p>
      </div>
    </div>
    <div style="background: #fff">
      <div class="customized-data">
        <p class="data-title">免费领取营销资料</p>
        <div
          class="item-data"
          v-for="(item, index) in dataList"
          :key="index"
          :style="index == 2 ? 'margin-bottom:0' : ''"
        >
          <div>
            <p class="data-name">{{ item.name }}</p>
            <p class="data-desc">
              {{ item.desc }}
            </p>
          </div>
          <div class="button" @click="getobtain">合作获取</div>
        </div>
      </div>
    </div>
    <div class="license">
      <p class="license-title">授权支持</p>
      <div class="license-image">
        <img src="../assets/images/zs2.png" class="license-img" alt="" />
        <img src="../assets/images/zs1.png" class="license-img" alt="" />
        <img
          src="../assets/images/zs3.png"
          class="license-img"
          style="height: 230px"
          alt=""
        />
      </div>
    </div>
    <div class="index_modular" style="background: #fff">
      <p class="modular-title">完善教学场景 全流程服务少儿编程教育</p>
      <div class="modular_box">
        <div
          class="item-modular"
          v-for="(item, index) in modularList"
          :key="index"
        >
          <img :src="item.image" class="modular-img" alt="" />
          <p class="modular_title" style="margin: 20px 0">{{ item.title }}</p>
          <div>
            <p
              style="margin-bottom: 5px"
              class="modular_content"
              v-for="(items, indexs) in item.child"
              :key="indexs"
            >
              {{ items }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="license">
      <p class="license-title">校区运营全面解决方案</p>
      <div class="license-image">
        <div
          class="item-license"
          v-for="(item, index) in schemeList"
          :key="index"
        >
          <img :src="item.image" class="license_img" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="Solution">
      <div class="solution_left">
        <p class="solution_title">
          为教育机构一键接入少儿编程、科创实验、竞赛考级
        </p>
        <p class="solution_content">助力教培机构开启科技教育新未来</p>
        <div>
          <p class="solution_item">
            ·获得专属编程教学与教务管理平台，高效灵活运营校区！
          </p>
          <p class="solution_item">·获取最新课程资源，为机构注入全新血液！</p>
        </div>
      </div>
      <div class="solution_right">
        <div class="right_title">
          <span>联系我们免费获取机构运营资料</span>
          <img src="../assets/images/but_sb.png" class="titl_img" alt="" />
        </div>
        <div
          class="right_content"
          v-for="(item, index) in meansList"
          :key="index"
        >
          <img
            src="../assets/images/but_icon_yes.png"
            class="data_img"
            alt=""
          />
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="customer">
      <img src="../assets/images/kefu.png" class="customer_img" alt="" />
      <p>客服在线 点击咨询</p>
    </div>
    <indexbottom></indexbottom>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";
export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      Pheight: 327, //按钮padding
      height: 505, //图片高度
      current: 3,
      schemeList: [
        {
          image: require("../assets/images/jjfa_icon1.png"),
          name: "招生拓客",
        },
        {
          image: require("../assets/images/jjfa_icon2.png"),
          name: "教务管理",
        },
        {
          image: require("../assets/images/jjfa_icon3.png"),
          name: "师生教学",
        },
        {
          image: require("../assets/images/jjfa_icon4.png"),
          name: "成果展示",
        },
        {
          image: require("../assets/images/jjfa_icon5.png"),
          name: "学员维护",
        },
      ],
      dataList: [
        {
          name: "招生物料",
          desc: "折页、易拉宝、宣传单页招生物料，支持各类招生营销和宣传活动举办",
        },
        {
          name: "校区装饰",
          desc: "编程元素，未来基地VIS全系支持，低成本落地少儿编程教学环境",
        },
        {
          name: "创作素材",
          desc: "独有原创IP人物，活泼支持小区编程教学和学生创作",
        },
      ],
      modularList: [
        {
          title: "课程资源更新",
          child: ["定期更新和新增课程丰富", "内容、提高质量"],
          image: require("../assets/images/ws_icon2.png"),
        },
        {
          title: "快速获取生源",
          child: ["线下招生物料下载", "线上赛事活动"],
          image: require("../assets/images/ws_icon3.png"),
        },
        {
          title: "校区师生管理",
          child: ["教师绩效考核", "学员状态跟进"],
          image: require("../assets/images/ws_icon4.png"),
        },
        {
          title: "师生教学互动",
          child: ["教师备课、授课学员", "学习创作"],
          image: require("../assets/images/ws_icon5.png"),
        },
        {
          title: "学员成果展示",
          child: ["课堂作品分享", "课后创作发布传播"],
          image: require("../assets/images/ws_icon6.png"),
        },
        {
          title: "家校信息同步",
          child: ["实时沟通学情报告", "创作作品一键分享"],
          image: require("../assets/images/ws_icon1.png"),
        },
        {
          title: "教师能力提升",
          child: ["备课教案分步指导", "提升老师授课技巧"],
          image: require("../assets/images/ws_icon7.png"),
        },
        {
          title: "校区运营数据",
          child: ["在读学员学情监控", "招生转化数据分析"],
          image: require("../assets/images/ws_icon8.png"),
        },
      ],
      meansList: [
        "全套编程课程使用",
        "开通教务系统校区账号",
        "教学平台账号无限添加",
        "编程创作线上社区",
        "独家编程工具",
        "专属网站页面招生引流",
        "招生物料自助下单",
        "参与平台比赛、活动",
      ],
    };
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.height = document.documentElement.clientWidth * 0.263;
        this.Pheight = this.height * 0.6475;
      })();
    };
  },
  methods: {
    getobtain() {
      this.$message("点击右下角客服按钮获取");
    },
  },
};
</script>

<style lang='less' scoped>
.html {
  background: #f8f8f8;
}
.banner {
  height: 505px;
  width: 100%;
  position: relative;
  .banner_img {
    width: 100%;
    height: auto;
  }
  .banner_btn {
    width: 215px;
    height: 49px;
    position: absolute;
    left: 22%;
    cursor: pointer;
  }
}
.customized-first {
  display: flex;
  align-items: center;
  width: 1100px;
  height: 310px;
  justify-content: space-between;
  margin: auto;
  .first-img {
    width: 440px;
    height: 250px;
  }
  .customized-title {
    font-size: 23px;
    color: #000;
    margin-bottom: 20px;
  }
  .customized-content {
    font-size: 16px;
    color: #9e9e9e;
  }
}
.customized-data {
  width: 1100px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: auto;
  text-align: center;
  .data-title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 46px;
  }
  .item-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    margin: auto;
    margin-bottom: 50px;

    .data-name {
      font-size: 23px;
      color: #000;
      text-align: left;
      margin-bottom: 8px;
    }
    .data-desc {
      color: #9e9e9e;
      font-size: 16px;
    }
  }
  .button {
    width: 193px;
    height: 49px;
    background: linear-gradient(180deg, #ffba00 0%, #ff8100 100%);
    border-radius: 41px 41px 41px 41px;
    text-align: center;
    line-height: 49px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
}

.license {
  padding: 60px 0;
  width: 1100px;
  margin: auto;
  text-align: center;
  .license-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .license-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    .item-license {
      display: flex;
      flex-direction: column;
      align-items: center;
      .license_img {
        width: 102px;
        height: 102px;
        margin-bottom: 15px;
      }
    }
  }
  .license-img {
    width: 333px;
    height: 222px;
  }
}

.index_modular {
  width: 100%;
  height: 632px;
  padding: 60px 0;
  background: url("../assets/images/home_bg_zylspx.png") no-repeat;
  background-size: 100% 100% !important;
  text-align: center;
  .modular-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .modular-content {
    font-size: 15px;
    margin-top: 15px;
    color: #7a7a7a;
    text-align: center;
  }
  .modular_img {
    width: 1100px;
    height: 410px;
    margin-top: 60px;
  }
  .modular-people {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .people-box {
    margin-top: 90px;
    text-align: center;
    .people-name {
      font-size: 23px;
      color: #000;
    }
    .people-rank {
      font-size: 16px;
      color: #9e9e9e;
    }
    .people-img {
      width: 200px;
      height: 200px;
      border-radius: 200px;
      margin-bottom: 40px;
    }
  }
  .modular_box {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .item-modular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 56px;
    width: 260px;
    height: 200px;
    border-radius: 10px 10px 10px 10px;
    .modular-img {
      width: 64px;
      height: 64px;
    }
    .modular_title {
      font-size: 23px;
      color: #000;
    }
    .modular_content {
      font-size: 16px;
      color: #9e9e9e;
      text-align: center;
    }
  }
}
.customized_img {
  width: 100%;
  height: 500px;
  margin-bottom: -5px;
}
.Solution {
  width: 100%;
  height: 505px;
  background: url("../assets/images/zljpjg_img_d.png");
  background-size: 100% 100%;
  padding: 0 416px;
  display: flex;
  justify-content: space-between;
  .solution_left {
    margin-top: 115px;
    color: #fff;
    .solution_title {
      font-size: 18px;
    }
    .solution_content {
      font-size: 36px;
      font-family: Alibaba PuHuiTi 2-105 Heavy, Alibaba PuHuiTi 20;
      font-weight: bold;
      line-height: 42px;
      background: linear-gradient(180deg, #60fff0 0%, #19ffd5 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 10px 0;
    }
    .solution_item {
      color: #74daff;
      font-size: 14px;
    }
  }
  .solution_right {
    margin-top: 83px;
    background: url("../assets/images/lxwm_bg.png") no-repeat;
    background-size: 100% 100%;
    width: 482px;
    height: 349px;
    padding: 27px 41px;
    .right_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 22px;
      color: #fff;
      margin-bottom: 20px;
      .title_img {
        width: 33px;
        height: 33px;
        margin-right: 15px;
      }
    }
    .right_content {
      display: flex;
      align-items: center;
      margin-left: 19px;
      line-height: 2;
      color: #74daff;
      font-size: 14px;
      .data_img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
    }
  }
}
</style>