<template>
  <div class="html">
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>

    <p class="title">申请试用</p>
    <div class="contact-content">
      <div class="content-left">
        <div class="item-left">
          <p>怎么称呼您?</p>
          <div class="item-input">
            <input type="text" v-model="name" />
            <span>老师</span>
          </div>
        </div>
        <div class="item-left">
          <p>您的机构名</p>
          <div class="item-input">
            <input type="text" v-model="companyName" />
          </div>
        </div>
      </div>
      <div class="content-left">
        <div class="item-left">
          <p>您所在的地区</p>
          <div
            class="item-input"
            style="border: 0; background: none; padding: 0"
          >
            <el-cascader
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange"
              placeholder="选择省市区"
              style="width: 450px; min-height: 48px"
              ref="cascader"
            >
            </el-cascader>
          </div>
        </div>
        <div class="item-left">
          <p>您的联系方式（电话）</p>
          <div class="item-input">
            <input type="number" v-model="phone" />
          </div>
        </div>
      </div>
      <!-- <div class="content-left">
        <div class="item-left" style="margin-bottom: 40px">
          <p>咨询电话</p>
          <div class="item-span">
            <span>400-1616-317</span>
            <span>0539-8809134</span>
          </div>
        </div>
        <div class="item-left" style="margin-bottom: 40px">
          <p>微信公众号</p>
          <div class="item-span">
            <img src="../assets/images/erwm.png" class="ewm" alt="" />
          </div>
        </div>
        <div class="item-left" style="margin-bottom: 40px">
          <p>竞赛平台服务订阅号</p>
          <div class="item-span">
            <img src="../assets/images/ewm2.png" class="ewm" alt="" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="means">
      <p class="title">对公汇款资料</p>
      <div class="content_menas">
        <div class="means_item">
          <div class="name">名称：</div>
          <div>深圳市六牛教育科技有限公司</div>
        </div>
        <div class="means_item">
          <div class="name">税号：</div>
          <div>91440300335257289K</div>
        </div>
        <div class="means_item">
          <div class="name">开户银行：</div>
          <div>中国建设银行股份有限公司深圳振兴支行</div>
        </div>
        <div class="means_item">
          <div class="name">账号：</div>
          <div>44201584600052527948</div>
        </div>
      </div>
    </div>
    <div class="submit" @click="Submit">提交</div>

    <div class="customer">
      <img
        src="../assets/images/kefu.png"
        @click="getcustomer"
        class="customer_img"
        alt=""
      />
      <p>客服在线 点击咨询</p>
    </div>
    <indexbottom></indexbottom>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      current: 6,
      name: "", //姓名
      region: "", //地区
      companyName: "", //机构名称
      phone: "", //电话
      content: "", //问题
      options: regionData,
      selectedOptions: [],
    };
  },
  methods: {
    // 提交
    Submit() {
      let data = {
        name: this.name,
        school: this.companyName,
        mobile: this.phone,
        area: this.region,
      };
      api.contact(data).then((res) => {
        this.$message({
          type: "info",
          message: res.info,
        });
        if (res.status == 1) {
          this.name = "";
          this.companyName = "";
          this.region = "";
          this.phone = "";
          this.content = "";
          this.selectedOptions = [];
          this.List.forEach((item) => {
            item.show = false;
          });
          setTimeout(() => {
            location.reload();
          }, 200);
        }
      });
    },
    // 地址选择
    handleChange(value) {
      this.region =
        CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    },
    // 选择
    getchange(e) {
      this.List.forEach((item) => {
        if (item.name == e.name) {
          item.show = !item.show;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/deep/.el-input__inner {
  height: 48px;
}
/deep/.el-input__icon {
  transform: rotate(-90deg);
}
/deep/.el-cascader .el-input .icon-arrow-down.is-reverse {
  transform: rotate(0deg);
}
.html {
  background: #f8f8f8;
}
.title {
  margin: 58px 0;
  text-align: center;
  font-size: 36px;
}
.contact-content {
  width: 960px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item-left {
    p {
      font-size: 15px;
      color: #7a7a7a;
      margin-bottom: 7px;
    }
    .item-span {
      font-size: 15px;
      color: #333;
      span {
        margin-right: 30px;
      }
    }
    .item-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      width: 450px;
      min-height: 48px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #d9d9d9;
      margin-bottom: 20px;
      input {
        outline: 0;
        border: 0;
        height: 100%;
        flex: 1;
        font-size: 15px;
      }
      textarea {
        height: 86px;
        padding: 10px 0;
        width: 100%;
        border: 0;
        outline: 0;
        box-sizing: border-box;
        resize: none;
        font-size: 15px;
      }
    }
    .check-box {
      display: flex;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;
      .check {
        width: 18px;
        height: 18px;
      }
    }
    .item-radio {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 600px;
      span {
        margin-left: 5px;
        font-size: 15px;
      }
    }
    .ewm {
      width: 113px;
      height: 113px;
    }
  }
}
.submit {
  width: 275px;
  height: 49px;
  background: linear-gradient(180deg, #ffba00 0%, #ff8100 100%);
  border-radius: 41px 41px 41px 41px;
  text-align: center;
  line-height: 49px;
  color: #fff;
  font-size: 18px;
  margin: 70px auto;
  cursor: pointer;
}
.means {
  .content_menas {
    width: 961px;
    height: 148px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #d9d9d9;
    margin: 38px auto;
    padding-top: 19px;
    .means_item {
      font-size: 15px;
      margin: 0 auto 12px;
      text-align: center;
      display: flex;
      align-items: center;
      width: 350px;
      .name {
        color: #9e9e9e;
        width: 75px;
        text-align: left;
      }
      .item_right {
        width: 180px;
      }
    }
  }
}
</style>