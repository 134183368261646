<template>
  <div>
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>
    <img src="../assets/images/kctx_banner.png" class="banner" alt="" />
    <div class="index_introduce">
      <p class="introduce_title">助力教培机构开启科技教育新未来</p>
      <p class="introduce_content">覆盖全年龄段的专业课程体系</p>
      <img src="../assets/images/home_img_kctx.png" class="home_img" alt="" />
    </div>
    <div class="index_modular">
      <div class="modular_box">
        <div
          class="item-modular"
          v-for="(item, index) in modularList"
          :key="index"
        >
          <img :src="item.image" class="modular-img" alt="" />
          <p class="modular_title">{{ item.title }}</p>
          <p class="modular_content">{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="index_modular" style="background: #fff; height: 652px">
      <p class="modular-title">专业的研发团队</p>
      <p class="modular-content">
        未来基地课程研发团队来自高校博士团队、教育心理学博士，深耕科技STEAM行业多年，具有丰富的课研，教学等教育行业工作经验
      </p>
      <div class="modular-people">
        <div
          class="people-box"
          v-for="(item, index) in peopleList"
          :key="index"
        >
          <img :src="item.image" class="people-img" alt="" />
          <p class="people-name">{{ item.name }}</p>
          <p
            class="people-rank"
            v-for="(items, indexs) in item.child"
            :key="indexs"
          >
            {{ items }}
          </p>
        </div>
      </div>
    </div>
    <div class="course">
      <div class="course-detail">
        <div class="detail-title">为教育机构运营量身打造的各类STEAM课程</div>
        <div class="detail-process">
          <div class="process-desc">
            <img
              src="../assets/images/kc_icon_d1.png"
              class="detail-img"
              alt=""
            />
            <p>邀约体验</p>
          </div>
          <img
            src="../assets/images/kc_icon_line.png"
            class="detail-line"
            alt=""
          />
          <div class="process-desc">
            <img
              src="../assets/images/kc_icon_d2.png"
              class="detail-img"
              alt=""
            />
            <p>转化短期班</p>
          </div>

          <img
            src="../assets/images/kc_icon_line.png"
            class="detail-line"
            alt=""
          />
          <div class="process-desc">
            <img
              src="../assets/images/kc_icon_d3.png"
              class="detail-img"
              alt=""
            />
            <p>转化长期班</p>
          </div>
        </div>
        <div class="course-type">
          <div class="title-name">体验课</div>
          <div class="title-name">专题课</div>
          <div class="title-name">正式课堂</div>
          <div
            class="type-content"
            v-for="(item, index) in courseList"
            :key="index"
          >
            <p>{{ item.title }}</p>
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="index_mechanism">
      <div class="mechanism_left">
        <div class="clr"></div>
        <div class="mechanism_title">
          <span>软硬件</span>
          <span style="color: #00baff">协同</span>
          <span>教学</span>
        </div>
        <div class="remark-content">让孩子感受科技 学以致用</div>
        <div class="remark-content">
          软硬件协同教学，增强课程的趣味性，强化编程的实际应用，让学习更有动力。
        </div>
        <div class="remark-content">
          内容由浅入深，先思考后实践，创新思维与实践案例相结合，以满足教学的需求。
        </div>
      </div>
      <img src="../assets/images/xtjx_img.png" class="home_img" alt="" />
    </div>
    <div class="customer">
      <img src="../assets/images/kefu.png" class="customer_img" alt="" />
      <p>客服在线 点击咨询</p>
    </div>
    <indexbottom></indexbottom>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";

export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      current: 1,
      peopleList: [
        {
          image: require("../assets/images/kc_icon_td1.png"),
          name: "刘阳",
          child: ["人工智能技术顾问", "济南大学导师", "中南大学博士"],
        },
        {
          image: require("../assets/images/kc_icon_td2.png"),
          name: "赵守盈",
          child: ["教育顾问", "贵州师范大学校长", "教育心理学博士"],
        },
        {
          image: require("../assets/images/kc_icon_td3.png"),
          name: "刘峰",
          child: ["人工智能技术顾问", "济南大学导师", "中南大学博士"],
        },
      ],
      // 模块列表
      modularList: [
        {
          title: "渐进式的难度梯度",
          content:
            "国家政策的大力扶持与推行，让人工智能少儿编程教育的普及，成为教育需背负起的行业使命。",
          image: require("../assets/images/kc_icon01.png"),
        },
        {
          title: "逻辑式课程",
          content:
            "在课堂环节的设置过程中，由基础概念和知识点为中心，不断进行发散和引申，并适当重现，实现编程学习的螺旋式上升。",
          image: require("../assets/images/kc_icon02.png"),
        },
        {
          title: "整体性策略下的知识网络化",
          content:
            "将编程和生活，编程和多种途径的综合学习，编程和各类知识联系在一起，让编程知识点的理解和记忆更牢固，并得以生活中应用。",
          image: require("../assets/images/kc_icon03.png"),
        },
        {
          title: "多学科综合",
          content:
            "除编程知识，学科知识也会被重点融入到课程中，让孩子运用多种学科知识，解决问题。激发孩子学习兴趣和动机。",
          image: require("../assets/images/kc_icon04.png"),
        },
        {
          title: "软硬件协同教学",
          content:
            "融入硬件编程的优势，强化基础知识的学习。通过软硬件协同教学，让孩子深入理解编程的实际应用，让编程学习更有趣、实用。",
          image: require("../assets/images/kc_icon05.png"),
        },
        {
          title: "逻辑与艺术交融",
          content:
            "通过设置有趣的故事化场景和角色，采用数千张精美的手绘素材，在潜移默化中培养孩子的审美和想象力。",
          image: require("../assets/images/kc_icon06.png"),
        },
      ],
      courseList: [
        {
          title: "日常体验课",
          content:
            "生动而有趣的日常体验课，激发孩子的编程学习兴趣，适用于日常的招生体验转化情境，让家长和孩子初步感受少儿编程。",
        },
        {
          title: "寒暑假课",
          content:
            "结合寒暑期节令特点，通过有趣的专题故事，让孩子在短时间内接触和了解编程概念和应用，感受编程魅力，对编程产生兴趣。",
        },
        {
          title: "多年龄层",
          content:
            "基于青少年儿童年龄、认知水平知识量基础特点，每类课程推出两个平行系列，分别适用大小年级段，教学效果更佳。",
        },
        {
          title: "节日主题课",
          content:
            " 将编程与节日主题巧妙结合，充分展现编程魅力和节日特色，适用于节日招生营销情境，在节日活动中完成招生转化。",
        },
        {
          title: "科学专题课",
          content:
            " 通过科学交叉专题拓展外延，让孩子直观地感受科学知识的应用，提升孩子对于其他学科的学习兴趣和成绩表现。",
        },
        {
          title: "多难度阶段",
          content:
            " Scratch 、Python 和 C++均包括基础、提升到应用阶段，通过循序渐进的难度设计，帮孩子更牢靠掌握编程概念、原理和应用。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.banner {
  height: 505px;
  width: 100%;
  object-fit: cover;
}
.index_introduce {
  width: 1100px;
  margin: auto;
  height: 688px;
  text-align: center;
  padding: 60px 0;
  .home_img {
    height: 415px;
    width: 100%;
    object-fit: fill;
  }
  .introduce_title {
    font-size: 36px;
    font-weight: bold;
  }
  .introduce_content {
    font-size: 15px;
    color: #7a7a7a;
    max-width: 680px;
    margin: 15px auto;
  }
}
.index_modular {
  width: 100%;
  height: 726px;
  padding: 60px 0;
  background: #f8f8f8;
  .modular-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
  .modular-content {
    font-size: 15px;
    margin-top: 15px;
    color: #7a7a7a;
    text-align: center;
  }
  .modular-people {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .people-box {
    margin-top: 90px;
    text-align: center;
    .people-name {
      font-size: 23px;
      color: #000;
      margin-bottom: 10px;
    }
    .people-rank {
      font-size: 16px;
      color: #9e9e9e;
      line-height: 2;
    }
    .people-img {
      width: 200px;
      height: 200px;
      border-radius: 200px;
      margin-bottom: 40px;
    }
  }
  .modular_box {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .item-modular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 0;
    margin-bottom: 60px;
    width: 300px;
    height: 200px;
    border-radius: 10px 10px 10px 10px;
    .modular-img {
      width: 96px;
      height: 96px;
    }
    .modular_title {
      font-size: 23px;
      color: #000;
    }
    .modular_content {
      font-size: 16px;
      max-width: 250px;
      margin-top: 10px;
      color: #9e9e9e;
      text-align: center;
    }
  }
}
.course {
  background: #1e2742;
  padding-top: 87px;
  padding-bottom: 75px;
  height: 900px;
  .course-detail {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .detail-title {
      font-size: 36px;
      color: #fff;
      font-weight: bold;
    }
    .detail-process {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 650px;
      margin: 80px auto;
      .detail-img {
        width: 102px;
        height: 102px;
        margin-bottom: 15px;
      }
      .detail-line {
        width: 117px;
        height: 6px;
      }
    }
    .process-desc {
      text-align: center;
      color: #fff;
      font-size: 16px;
    }
    .course-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .title-name {
        width: 360px;
        height: 73px;
        background: #313b59;
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        line-height: 73px;
      }
      .type-content {
        width: 360px;
        height: 155px;
        background: #313b59;
        padding: 26px 32px;
        font-size: 15px;
        color: #c4c4c4;
        margin-top: 10px;
      }
    }
  }
}
.index_mechanism {
  width: 1100px;
  margin: 60px auto 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .home_img {
    width: 662px;
    height: 502px;
  }
  .mechanism_left {
    padding-top: 26px;
    .clr {
      width: 62px;
      height: 4px;
      background: #00baff;
    }
    .mechanism_title {
      font-size: 36px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .remark-content {
      font-size: 15px;
      margin-bottom: 15px;
      max-width: 370px;
      color: #7a7a7a;
    }
  }
}
.customer {
  width: 221px;
  height: 46px;
  background: #00baff;
  border-radius: 23px 23px 23px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 50px;
  .customer_img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}
</style>