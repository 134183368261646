<template>
  <div>
    <div class="head">
      <img src="../assets/images/logo.png" class="logo" alt="" />
      <div class="head-nav">
        <div
          v-for="(item, index) in navList"
          :key="index"
          :class="current == index ? 'nav-item' : 'nav-item1'"
          @click="Change(index)"
        >
          <p>{{ item.name }}</p>
          <div class="clr"></div>
        </div>
      </div>
      <div class="head-login">
        <div class="button" @click="getlogin">
          <span>登录</span>
          <img
            src="../assets/images/more-bottom.png"
            class="more-bottom"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: Number,
  },
  data() {
    return {
      navList: [
        {
          name: "首页",
        },
        {
          name: "课程体系",
        },
        {
          name: "师资培训",
        },
        {
          name: "平台定制",
        },
        {
          name: "赛事&考级",
        },
        {
          name: "公司介绍",
        },
        {
          name: "联系我们",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    getlogin() {
      let url = "http://teach.lnsteam.com/admin/";
      window.open(url, "_blank");
    },
    Change(e) {
      if (e == 0) {
        this.$router.push({
          path: "/",
        });
      } else if (e == 1) {
        this.$router.push({
          path: "/course",
        });
      } else if (e == 2) {
        this.$router.push({
          path: "/train",
        });
      } else if (e == 3) {
        this.$router.push({
          path: "/customized",
        });
      } else if (e == 4) {
        this.$router.push({
          path: "/match",
        });
      } else if (e == 5) {
        this.$router.push({
          path: "/company",
        });
      } else {
        this.$router.push({
          path: "/contactus",
        });
      }
    },
  },
};
</script>

 <style lang="less" scoped>
.head {
  height: 78px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #fff;
  .logo {
    width: 137px;
    height: 55px;
  }
  .head-nav {
    display: flex;
    align-items: center;
    width: 800px;
    justify-content: space-between;
    font-size: 14px;
    .nav-item1 {
      color: #333;
      cursor: pointer;
    }
    .nav-item {
      color: #00baff;
      position: relative;
      font-weight: bold;
      cursor: pointer;
      .clr {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        margin: auto;
        width: 22px;
        height: 2px;
        background: #00baff;
      }
    }
    .nav-item1:hover {
      color: #00baff;
    }
  }
  .head-login {
    display: flex;
    align-items: center;
    .phone {
      display: flex;
      align-items: center;
      .phone-img {
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }
      .mobile {
        font-size: 14px;
        color: #909090;
      }
    }
    .button {
      display: flex;
      align-items: center;
      width: 121px;
      height: 35px;
      border-radius: 21px 21px 21px 21px;
      border: 1px solid #e4e4e4;
      justify-content: center;
      margin-left: 40px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
    .button:hover {
      background: #00baff;
      color: #fff;
    }
    .more-bottom {
      width: 11px;
      height: 6px;
      margin-left: 7px;
    }
  }
}
</style>