<template>
  <div class="html">
    <div class="fiexd">
      <indexTitle :current="current"></indexTitle>
    </div>
    <div class="banner">
      <img src="../assets/images/home_banner.png" class="banner_img" alt="" />
    </div>
    <div class="index-content">
      <div
        class="item-content"
        v-for="(item, index) in contentList"
        :key="index"
      >
        <div class="item-box">
          <img :src="item.image" class="content-img" alt="" />
          <div>
            <p class="title">{{ item.title }}</p>
            <p class="desc" :style="index == 2 ? 'max-width:240px' : ''">
              {{ item.content }}
            </p>
          </div>
        </div>
        <div class="button">
          <div class="btn-content" @click="getgetail(index)">
            <span>点击进入</span>
            <img src="../assets/images/more.png" class="more" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="index_modular">
      <div class="modular_box">
        <div
          class="item-modular"
          v-for="(item, index) in modularList"
          :key="index"
        >
          <img :src="item.image" class="modular-img" alt="" />
          <p class="modular_title">{{ item.title }}</p>
          <p class="modular_content">{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div style="background: #fff">
      <div class="index_introduce">
        <p class="introduce_title">科学全面的课程体系</p>
        <p class="introduce_content">
          基于学生的学习特点、课程内容的内在联系，通过对零散杂乱的知识进行梳理、整合，构建网络化、序列化、具有合理难度梯度的知识与课程体系
        </p>
        <img
          src="../assets/images/home_img_kctx.png"
          class="home_stysem"
          alt=""
        />
      </div>
    </div>
    <div class="index_mechanism">
      <div class="mechanism_left">
        <div class="clr"></div>
        <div class="mechanism_title">
          <span>助力教培机构开启科技教育</span>
          <span style="color: #00baff">新未来</span>
        </div>
        <div
          class="item_remark"
          v-for="(item, index) in remarkList"
          :key="index"
        >
          <img :src="item.image" class="remark_img" alt="" />
          <div>
            <p class="remark_title">{{ item.title }}</p>
            <p class="remark_content">
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
      <img src="../assets/images/home_img.png" class="home_img" alt="" />
    </div>
    <div class="operate">
      <img
        src="../assets/images/home_img_qfwyyfuzc.png"
        class="operate_img"
        alt=""
      />
    </div>
    <indexbottom></indexbottom>
    <div class="customer">
      <img src="../assets/images/kefu.png" class="customer_img" alt="" />
      <p>客服在线 点击咨询</p>
    </div>
    <div class="index_popup">
      <indexpopup></indexpopup>
    </div>
  </div>
</template>

<script>
import indexTitle from "../components/indexTitle.vue";
import indexbottom from "../components/indexbottom.vue";
import indexpopup from "../components/indexpopup.vue";
export default {
  components: {
    indexTitle,
    indexbottom,
    indexpopup,
  },
  data() {
    return {
      current: 0,
      contentList: [
        {
          title: "管理平台",
          content: "报名、开班、续费",
          image: require("../assets/images/home_icon_jwglpt.png"),
        },
        {
          title: "教学平台",
          content: "备课、开课、培训",
          image: require("../assets/images/home_icon_jspxpt.png"),
        },
        {
          title: "学习平台",
          content: "上课、竞赛考级、成果分享",
          image: require("../assets/images/home_icon_jxpt.png"),
        },
      ],
      remarkList: [
        {
          image: require("../assets/images/home_xwl_icon02.png"),
          title: "响应国家政策助推，肩负教育行业使命",
          content:
            "国家政策的大力扶持与推行，让人工智能少儿编程教育的普及，成为教育需背负起的行业使命。",
        },
        {
          image: require("../assets/images/home_xwl_icon01.png"),
          title: "紧跟AI时代潮流 接轨科技前沿",
          content:
            "人工智能技术发展迅速，Ai的应用与融合越来越广泛，变成学习已成为时代发展潮流。",
        },
        {
          image: require("../assets/images/home_xwl_icon03.png"),
          title: "杜绝课程同质化 提升机构差异竞争力",
          content:
            "当前教育培训行业，课程同质化现象严重，增加少儿编程，可有效提升机构竞争力。",
        },
        {
          image: require("../assets/images/home_xwl_icon04.png"),
          title: "课程体系可持续学习 延长学员学习周期",
          content:
            "课程体系全面，可拓展性强，学员可持续学习，更好地延长学员学习周期。",
        },
      ],
      // 模块列表
      modularList: [
        {
          title: "课后家校沟通",
          content: "学情反馈、成果展示",
          image: require("../assets/images/yzkc_icon01.png"),
        },
        {
          title: "专业老师培训",
          content: "线上+线下双渠道输出的专业化培训！",
          image: require("../assets/images/yzkc_icon02.png"),
        },
        {
          title: "校区运营服务系统",
          content: "招生、引流、拓客方案实时更新",
          image: require("../assets/images/yzkc_icon04.png"),
        },
        {
          title: "教学与管理平台",
          content: "教学管理与课程资源一体化云平台！",
          image: require("../assets/images/yzkc_icon03.png"),
        },
        {
          title: "标准教学材料",
          content: "特有“半小时备好课”系统！",
          image: require("../assets/images/yzkc_icon05.png"),
        },
        {
          title: "专业竞赛考级服务平台",
          content: "涵盖教育部赛事白名单主流赛项",
          image: require("../assets/images/yzkc_icon06.png"),
        },
      ],
      isFixed: false, //当滚动条高度大于152时是否定位
      scrollHeight: 150,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.initHeight);
  },
  methods: {
    getgetail(e) {
      let url = "";
      if (e == 2 || e == 1) {
        url = "http://teach.lnsteam.com/";
      } else {
        url = "http://teach.lnsteam.com/admin/Login/login.html";
      }
      window.open(url, "_blank");
    },
    // 实现吸顶效果，判断滚动条距离顶部的距离
    initHeight() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isFixed = scrollTop > this.scrollHeight ? true : false;
    },
    destroyed() {
      window.removeEventListener("scroll", this.initHeight, false);
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.html {
  height: 100%;
}
.banner {
  width: 100%;
  height: 690px;

  .banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.index-content {
  width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  top: -100px;
  .item-content {
    width: 347px;
    height: 181px;
    background: #ffffff;
    box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.07999999821186066);
    border-radius: 8px 8px 8px 8px;
    padding-top: 40px;
    padding-bottom: 20px;
    .item-box {
      display: flex;
      align-items: center;
    }
    .content-img {
      width: 90px;
      height: 90px;
      margin: 0 18px;
    }
    .button {
      display: flex;
      justify-content: flex-end;
      padding-right: 18px;
    }
    .btn-content {
      display: flex;
      align-items: center;
      width: 106px;
      height: 38px;
      background: #ecf6ff;
      border-radius: 19px 19px 19px 19px;
      justify-content: center;
      font-size: 14px;
      color: #00baff;
      cursor: pointer;
      .more {
        width: 18px;
        height: 18px;
        margin-left: 7px;
      }
    }
    .title {
      font-size: 24px;
      color: #333;
    }
    .desc {
      font-size: 12px;
      max-width: 200px;
      text-align: center;
      margin-top: 10px;
      color: #9e9e9e;
    }
  }
}
.index_mechanism {
  width: 1100px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .home_img {
    width: 450px;
    height: 560px;
  }
  .mechanism_left {
    padding-top: 26px;
    .clr {
      width: 62px;
      height: 4px;
      background: #00baff;
    }
    .mechanism_title {
      font-size: 36px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-top: 15px;
      margin-bottom: 70px;
    }
    .item_remark {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .remark_img {
        width: 52px;
        height: 52px;
        margin-right: 19px;
      }
      .remark_title {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .remark_content {
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }
}
.index_modular {
  width: 100%;
  height: 676px;
  background-size: 100% 100% !important;
  background: #eaf2f8;
  padding: 60px 0;
  .modular_box {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .item-modular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 0;
    background: #ffffff;
    margin-bottom: 36px;
    width: 320px;
    height: 260px;
    border-radius: 10px 10px 10px 10px;
    .modular-img {
      width: 96px;
      height: 96px;
    }
    .modular_title {
      font-size: 26px;
      color: #000;
    }
    .modular_content {
      font-size: 14px;
      color: #9e9e9e;
    }
  }
}
.index_introduce {
  width: 1100px;
  margin: auto;
  height: 765px;
  text-align: center;
  padding: 60px 0;
  .home_stysem {
    width: 100%;
    height: 415px;
    object-fit: fill;
  }
  .introduce_title {
    font-size: 36px;
    font-weight: bold;
  }
  .introduce_content {
    font-size: 15px;
    color: #7a7a7a;
    max-width: 680px;
    margin: 15px auto;
  }
}
.operate {
  width: 100%;
  height: 800px;
  background: #f8f8f8;
  text-align: center;
  padding-top: 100px;
  .operate_img {
    width: 1100px;
    height: 100%;
  }
}
.customer {
  width: 221px;
  height: 46px;
  background: #00baff;
  border-radius: 23px 23px 23px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 50px;
  opacity: 0;
  .customer_img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}
</style>