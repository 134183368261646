<template>
  <div>
    <div class="popup">
      <div
        class="item"
        style="background: #00baff; border: 0"
        @mousemove="mousemove(1)"
        @mouseout="mouseout(1)"
      >
        <img src="../assets/images/phone_btn.png" class="img" alt="" />
      </div>
      <div class="item" @mousemove="mousemove(2)" @mouseout="mouseout(2)">
        <img src="../assets/images/ewm_btn.png" class="img" alt="" />
      </div>
      <div class="item" style="cursor: pointer" @click="backTop">
        <img src="../assets/images/top.png" class="img" alt="" />
      </div>
      <div v-if="show" class="phone_popup">
        <div class="item_phone">
          <img src="../assets/images/call.png" class="phone_img" alt="" />
          <div>
            <p class="name">客服热线1</p>
            <p>400-1616-317</p>
          </div>
        </div>
        <div class="clr"></div>
        <div class="item_phone">
          <img src="../assets/images/call.png" class="phone_img" alt="" />
          <div>
            <p class="name">客服热线2</p>
            <p>400-1616-317</p>
          </div>
        </div>
      </div>
      <div v-if="ewmShow" class="ewm_popup">
        <div class="item_ewm">
          <img src="../assets/images/erwm.png" class="ewm" alt="" />
          <div>微信公众号</div>
        </div>
        <div class="clr"></div>
        <div class="item_ewm">
          <img src="../assets/images/ewm2.png" class="ewm" alt="" />
          <div>竞赛平台服务订阅号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      ewmShow: false,
      gotop: false,
    };
  },
  mounted() {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // backTop() {
    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // 鼠标悬浮
    mousemove(e) {
      if (e == 1) {
        this.show = true;
      } else {
        this.ewmShow = true;
      }
    },
    // 鼠标离开
    mouseout(e) {
      if (e == 1) {
        this.show = false;
      } else {
        this.ewmShow = false;
      }
    },
  },
};
</script>

<style lang='less' scoped>
div {
  box-sizing: border-box;
}
.item {
  width: 46px;
  height: 46px;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border: 1px solid #bcbcbc;

  .img {
    width: 32px;
    height: 32px;
  }
}
.phone_popup {
  width: 238px;
  height: 175px;
  background: #ffffff;
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.05999999865889549);
  border: 1px solid #dddddd;
  position: fixed;
  right: 80px;
  top: 35%;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .phone_img {
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }
  .clr {
    border-bottom: 1px solid #f4f4f4;
    width: 100%;
    height: 1px;
  }
  .item_phone {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #33ad88;
    font-weight: bold;
    width: 100%;
    .name {
      font-size: 12px;
      font-weight: normal;
      color: #333;
      line-height: 2;
    }
  }
}

.ewm_popup {
  width: 150px;
  height: 337px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05999999865889549);
  opacity: 1;
  border: 1px solid #dddddd;
  padding: 6px;
  position: fixed;
  right: 80px;
  top: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .ewm {
    width: 113px;
    height: 113px;
    margin-bottom: 6px;
  }
  .clr {
    border-bottom: 1px solid #f4f4f4;
    width: 100%;
    height: 1px;
  }
  .item_ewm {
    font-size: 15px;
    color: #7a7a7a;
    text-align: center;
  }
}
</style>